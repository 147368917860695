import React, { useState } from 'react';


import joblist from '../../joblist';



const CodePage = function () {
    const [currentJob, setCurrentJob] = useState(5);
    var j1;
    
    const toggleJob = (id) => {
        setCurrentJob(id);
    };
   
    j1 = Object.entries(joblist.find(e1 => e1["id"] === currentJob));
    

    return(
        <>
            <div className='col-3 col-12-small' style={{marginTop:'1em', borderRight:'1px solid #333'}}>
                <ul className="alt" >
                    {
                        joblist.map(j2 => {
                            return (j2["id"] === currentJob) 
                                ? <li role="button" key={j2["id"]} style={{backgroundColor:'#1c2841', color:'#fff', paddingLeft:'.25em', }}
                                    onClick={() => toggleJob(j2["id"])}>{j2["Job title"]}</li>
                                : <li role="button" key={j2["id"]} style={{paddingLeft:'.25em'}}
                                    onClick={() => toggleJob(j2["id"])}>{j2["Job title"]}</li>;
                            
                        })
                        
                    }
                
                </ul>
            </div>
            
            <div className='col-9 col-12-small' style={{marginTop:'1em'}}>
                {
                    j1.map(j2 => {
                        
                        return (j2[0]==='id') ? null :
                            <p key={j2[0]} style={{margin:"0 0 .75em .5em"}}><span style={{fontWeight:600,color:'black'}}>{j2[0] + " - "}</span>  {j2[1]}</p>;
                    })
                }
            </div>
        </>
    );
    
};

export default CodePage;
